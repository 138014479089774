export function studiesTable(args) {
  return {
    studies: args.studies,
    searchTerm: "",
    get items() {
      let val = this.studies;

      if (this.searchTerm) {
        const normalizedTearm = this.searchTerm.trim().toLowerCase();
        val = val.filter((study) => {
          return (
            study.name.toLowerCase().includes(normalizedTearm) ||
            study.creator?.name?.toLowerCase()?.includes(normalizedTearm)
          );
        });
      }

      return val;
    },
  };
}
