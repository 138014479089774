import { BumpChart, LineChart, StackedAreaChart } from "@get-dx/d3-charts";
import {
  getQueryStringParams,
  setQueryStringParam,
  deleteQueryStringParam,
  objectToQueryString,
  generatePreviousComparisonValues,
  generateBenchmarkComparisonValues,
  arrayRange,
  sortFunc,
  comparisonOptionsMap,
  snapshotSquadsFromCache,
  generateOrgComparisonValues,
  numberToSpelling,
} from "../../shared/utils";
import { chartTooltipHtml } from "../chartTooltipHtml";

const targetPositionCountMap = new Map([
  [0, "zero_position_count"],
  [1, "one_position_count"],
  [2, "two_position_count"],
  [3, "three_position_count"],
  [4, "four_position_count"],
  [5, "five_position_count"],
  [6, "six_position_count"],
]);

const benchmarkDiffConversions = new Map([
  ["vs_prev", "prev_percentage"],
  ["vs_org", "vs_org"],
  ["vs_50th", "benchmark_50"],
  ["vs_75th", "benchmark_75"],
  ["vs_90th", "benchmark_90"],
]);

const COMMENT_PAGE_SIZE = 25;

function generateComparisonValues(
  resource,
  benchmark,
  resourceType = "factor",
) {
  let comparisonValues = [];

  if (
    ["vs_prev", "prev_percentage"].includes(benchmark) &&
    resource.values.length > 1
  ) {
    comparisonValues = generatePreviousComparisonValues(resource);
  } else if (
    !["vs_prev", "prev_percentage"].includes(benchmark) &&
    benchmark !== "vs_org"
  ) {
    comparisonValues = generateBenchmarkComparisonValues(
      resource,
      resourceType,
      benchmark,
    );
  }

  return comparisonValues;
}

export function snapshotSentiment(defaults) {
  const {
    baseUrl,
    dxiDrilldownTabs,
    minResponseThreshold,
    drilldownTabs,
    maxBehavioralQuestions,
    tagOptions,
    hasPreviousSnapshot,
    snapshotId,
    branchingEnabled,
    showIntro,
    hasHierarchyGroups,
    exportCommentsUrl,
  } = defaults;

  const targetedFactor = getQueryStringParams().get("factor_id");
  const dxiTargeted = getQueryStringParams().get("dxi");

  return {
    dxiDrilldownTabs,
    allComments: [],
    commentIds: new Set(),
    commentSearchTerm: "",
    commentSearchResults: [],
    minResponseThreshold,
    commentsLoaded: false,
    searchCommentsLoaded: false,
    hasMoreComments: false,
    commentPage: 0,
    resultItemsLoaded: false,
    resultItems: [],
    responseCount: null,
    recipientCount: null,
    drilldownModalOpen: !!targetedFactor,
    targetedFactor,
    trends: null,
    xStart: null,
    maxBehavioralQuestions,
    xEnd: null,
    dxiScore: null,
    dxiScoreWithoutBenchmarks: null,
    dxiModalOpen: !!dxiTargeted,
    orgDxiScore: null,
    dxi50: null,
    dxi75: null,
    dxi90: null,
    tabDataLoaded: false,
    selectedDrilldownTab: getQueryStringParams().get("ddt"),
    previousDxiScore: null,
    drilldownData: {},
    maxTeamPriority: 0,
    selectedFactorRankChange: null,
    maxTeamScoreForDriver: 0,
    privateCommentsVisible: false,
    squadNamesFromSquadIds: [],
    showTeamBreakdown: true,
    showCommentsSearch: false,
    viewAllDrivers: false,
    exportCommentsUrl,
    init() {
      this.$root.addEventListener("dx:squadsTree:init", () => {
        this.determineSquadNamesFromSquadIds();
      });

      this.fetchAll().then(() => {
        this.calculateDxi();
        this.setDxiComparisonValues();

        // Default this page to sort by priority if not specified in URL
        if (!getQueryStringParams().get("sb")) {
          this.$store.snapshotResultFilters.sortBy = "priority";
          this.$store.snapshotResultFilters.sortReverse = false;
        }

        if (this.selectedDrilldownTab) {
          if (this.selectedDrilldownTab == "comments") {
            const privParam = getQueryStringParams().get("priv");
            if (privParam == null) {
              setQueryStringParam("priv", false);
            }
          }

          if (dxiTargeted) {
            const selectedItem = dxiDrilldownTabs.find(
              (t) => t.tab == this.selectedDrilldownTab,
            );
            this.fetchTab(this.selectedDrilldownTab, selectedItem.url, "dxi");
          } else {
            const selectedItem = drilldownTabs.find(
              (t) => t.tab == this.selectedDrilldownTab,
            );
            this.fetchTab(
              this.selectedDrilldownTab,
              selectedItem.url,
              "driver",
            );
          }
        }

        if (showIntro) {
          setTimeout(() => {
            let steps = [
              {
                element: document.querySelector(".x-team-filter"),
                intro: "Filter results by team.",
              },
              {
                element: document.querySelector(".x-driver"),
                intro: "Click on a row to view more details.",
              },
            ];

            if (branchingEnabled) {
              steps = [
                {
                  element: document.querySelector(".x-snapshot-branches"),
                  intro: "Switch snapshot branches.",
                },
                ...steps,
              ];
            }

            window
              .introJs()
              .setOptions({
                exitOnEsc: false,
                exitOnOverlayClick: false,
                doneLabel: "Got it",
                scrollToElement: false,
                disableInteraction: true,
                steps,
              })
              .start()
              .oncomplete(() => {
                window.ack(showIntro);
              });
          }, 50);
        }
      });

      this.$store.snapshotResultFilters.hasHierarchyGroups = hasHierarchyGroups;
    },
    get tagNamesFromTagIds() {
      const selectedTags = tagOptions.filter(
        (tag) =>
          this.tagIds.includes(String(tag.id)) || this.tagIds.includes(tag.id),
      );

      return selectedTags.map((tag) => tag.name);
    },
    get visibleDrilldownTabs() {
      let filteredTabs = drilldownTabs.filter((drilldownTab) => {
        if (drilldownTab.tab == "team_breakdown") return this.showTeamBreakdown;
        if (drilldownTab.tab == "priority") return hasPreviousSnapshot;
        // Related workflows requires at least one workflow
        if (drilldownTab.tab == "related_workflows")
          return this.selectedFactor()?.has_workflows;
        // Recommendations requires an article
        if (drilldownTab.tab == "recommendations")
          return this.selectedFactor()?.atlas_article_id;

        return true;
      });

      if (hasHierarchyGroups) {
        filteredTabs = filteredTabs.map((t) =>
          t.tab == "team_breakdown" ? { ...t, text: "Breakdown" } : t,
        );
      }

      if (
        this.responseCount < minResponseThreshold &&
        this.selectedFactor()?.comment_count > 0
      ) {
        filteredTabs = filteredTabs.filter((t) => t.tab == "comments");
      } else if (
        this.responseCount < minResponseThreshold &&
        this.selectedFactor()?.comment_count == 0
      ) {
        // NOTE: This case technically shouldn't happen because the modal is prevented from opening if the conditions aren't met.
        // But with the change in logic in the sentiment/overview partial, this is a good safeguard.
        filteredTabs = [];
      }

      return filteredTabs;
    },
    get visibleDxiDrilldownTabs() {
      let filteredTabs = this.dxiDrilldownTabs;

      if (hasHierarchyGroups) {
        filteredTabs = filteredTabs.map((t) =>
          t.tab == "team_breakdown" ? { ...t, text: "Breakdown" } : t,
        );
      }

      return filteredTabs;
    },
    get cachedSquads() {
      return snapshotSquadsFromCache(snapshotId);
    },
    get showOrgComparision() {
      return this.squadIds.length > 0;
    },
    get showScores() {
      return this.responseCount >= minResponseThreshold;
    },
    get compareTo() {
      return this.$store.snapshotResultFilters.compareTo;
    },
    get previousSnapshotId() {
      return this.$store.snapshotResultFilters.previousSnapshotId;
    },
    get sortReverse() {
      return this.$store.snapshotResultFilters.sortReverse;
    },
    get sortBy() {
      return this.$store.snapshotResultFilters.sortBy;
    },
    get drilldownSortBy() {
      return this.$store.snapshotResultFilters.drilldownSortBy;
    },
    get drilldownSortReverse() {
      return this.$store.snapshotResultFilters.drilldownSortReverse;
    },
    get tagIds() {
      return this.$store.snapshotResultFilters.tagIds;
    },
    get driverComments() {
      if (this.commentSearchResults.length > 0)
        return this.commentSearchResults;

      if (!this.drilldownData.comments?.data) return [];

      return this.drilldownData.comments.data;
    },
    get responseCompletionPercentage() {
      if (!this.recipientCount) return `0 of 0 responded`;

      const percentage = Math.round(
        (this.responseCount * 100.0) / this.recipientCount,
      );

      return `${this.responseCount} of ${this.recipientCount} responded (${percentage}%)`;
    },
    get squadIds() {
      return this.$store.snapshotResultFilters.squadIds;
    },
    get hasMultipleTrendValues() {
      return this?.trends?.values?.length > 1;
    },
    get noSearchResults() {
      return (
        this.commentSearchResults.length == 0 &&
        this.commentSearchTerm &&
        this.searchCommentsLoaded
      );
    },
    calculateDxi() {
      const relevantItems = this.driverItems;
      const totalScore = relevantItems.reduce((sum, obj) => sum + obj.score, 0);

      this.dxiScore = Math.round(totalScore / relevantItems.length);

      const nonCustomItems = this.driverItems.filter((i) => i.benchmark_50);
      const nonCustomScores = nonCustomItems.reduce(
        (sum, obj) => sum + obj.score,
        0,
      );

      this.dxiScoreWithoutBenchmarks = Math.round(
        nonCustomScores / nonCustomItems.length,
      );
    },
    calculateOrgDxi() {
      const relevantItems = this.driverItems;
      const totalScore = relevantItems.reduce(
        (sum, obj) => sum + obj.org_score,
        0,
      );

      this.orgDxiScore = Math.round(totalScore / relevantItems.length);
    },
    calculatePreviousDxiScore() {
      const previousComparativeItems = this.resultItems
        .filter((i) => !i.current_snapshot)
        .filter((item) => item.prev_score != null);

      if (previousComparativeItems.length == 0) {
        this.previousDxiScore = null;
        return;
      }

      const totalScore = previousComparativeItems.reduce(
        (sum, obj) => sum + obj.prev_score,
        0,
      );

      return (this.previousDxiScore = Math.round(
        totalScore / previousComparativeItems.length,
      ));
    },
    get dxiComparisonValue() {
      if (
        this.dxiScoreWithoutBenchmarks == null ||
        isNaN(this.dxiScoreWithoutBenchmarks)
      )
        return null;

      if (this.compareTo == "vs_50th") {
        return this.dxiScoreWithoutBenchmarks - this.dxi50;
      } else if (this.compareTo == "vs_75th") {
        return this.dxiScoreWithoutBenchmarks - this.dxi75;
      } else if (this.compareTo == "vs_90th") {
        return this.dxiScoreWithoutBenchmarks - this.dxi90;
      } else if (this.compareTo == "vs_prev") {
        return this.previousDxiScore != null
          ? this.dxiScore - this.previousDxiScore
          : null;
      } else if (this.compareTo == "vs_org") {
        return this.dxiScore - this.orgDxiScore;
      }
      return null;
    },
    setDxiComparisonValues() {
      this.calculatePreviousDxiScore();
      this.dxi50 = this.calculateDxiBenchmark("vs_50th");
      this.dxi75 = this.calculateDxiBenchmark("vs_75th");
      this.dxi90 = this.calculateDxiBenchmark("vs_90th");
      this.calculateOrgDxi();
    },
    calculateDxiBenchmark(benchmarkComp) {
      const benchmarkAttribute = benchmarkDiffConversions.get(benchmarkComp);
      const relevantItems = this.driverItems.filter(
        (item) => item[benchmarkAttribute],
      );
      const totalScore = relevantItems.reduce(
        (sum, obj) => sum + obj[benchmarkAttribute],
        0,
      );

      return Math.round(totalScore / relevantItems.length);
    },
    selectedFactorScore() {
      return this.selectedFactor()?.score;
    },
    get selectedFactorIs5Point() {
      return this.selectedFactor()?.scale_points == 5;
    },
    get selectedFactorIs3Point() {
      return this.selectedFactor()?.scale_points == 3;
    },
    selectedFactorOption(position) {
      let label;

      if (this.selectedFactorIs3Point) {
        if (position == 1) label = "Bad";
        if (position == 2) label = "So-so";
        if (position == 3) label = "Good";
      }

      if (this.selectedFactorIs5Point) {
        if (position == 1) label = "Never";
        if (position == 2) label = "Rarely";
        if (position == 3) label = "Sometimes";
      }

      return label;
    },
    selectedFactor() {
      if (this.targetedFactor == null) return "";

      return this.driverItems.find((row) => row.id == this.targetedFactor);
    },
    selectedFactorPrompt() {
      return this.selectedFactor()?.prompt;
    },
    selectedFactorSummary() {
      return this.selectedFactor()?.summary;
    },
    percentageOfResponsesOverview(positionCount, totalFieldCount) {
      const item = this.selectedFactor();

      if (!item) return;

      if (item[totalFieldCount] === 0) return "0%";

      return `${Math.round(
        (100 * item[positionCount]) / item[totalFieldCount],
      )}%`;
    },
    percentageOfResponses(item, positionCount) {
      if (item.count === 0) return "0%";

      return `${Math.round((100 * item[positionCount]) / item.count)}%`;
    },
    selectedFactorComparison() {
      const rowData = this.selectedFactor();

      if (!rowData) return;

      return rowData[this.compareTo];
    },
    modalTitle() {
      return this.selectedFactor()?.name;
    },
    openDrilldownModal(selectedFactor) {
      // No data to show in modal and no comments to display
      if (
        this.responseCount < minResponseThreshold &&
        !selectedFactor.comment_count
      )
        return;

      this.drilldownModalOpen = true;
      // Defaults to overview tab when opening modal
      this.targetedFactor = selectedFactor.id;

      setQueryStringParam("factor_id", selectedFactor.id);
      if (
        this.responseCount < minResponseThreshold &&
        selectedFactor.comment_count > 0
      ) {
        this.selectedDrilldownTab = "comments";
        setQueryStringParam("ddt", "comments");
      } else {
        this.selectedDrilldownTab = "overview";
        setQueryStringParam("ddt", "overview");
      }

      const selectedItem = drilldownTabs.find(
        (t) => t.tab == this.selectedDrilldownTab,
      );
      if (selectedItem?.url) {
        this.fetchTab(this.selectedDrilldownTab, selectedItem.url, "driver");
      }

      // Related workflows tends to be a slower query
      const relatedWorkflowTab = this.visibleDrilldownTabs.find(
        (t) => t.tab == "related_workflows",
      );

      if (relatedWorkflowTab) {
        this.fetchTabDataBackground(
          relatedWorkflowTab.tab,
          relatedWorkflowTab.url,
        );
      }
    },
    openDxiModal() {
      if (this.responseCount < minResponseThreshold) return;
      this.dxiModalOpen = true;
      setQueryStringParam("ddt", "overview");
      setQueryStringParam("dxi", "true");
      this.fetchTab(
        this.dxiDrilldownTabs[0].tab,
        this.dxiDrilldownTabs[0].url,
        "dxi",
      );
    },
    hideSentimentDetails() {
      this.drilldownModalOpen = false;
      this.dxiModalOpen = false;
      this.targetedFactor = null;
      this.selectedDrilldownTab = null;
      // Clear the data so we don't run into weird cache issues.
      this.drilldownData = {};
      this.trends = null;
      this.privateCommentsVisible = false;
      this.commentSearchTerm = "";
      this.commentSearchResults = [];
      this.searchCommentsLoaded = false;

      deleteQueryStringParam("ddt");
      deleteQueryStringParam("dxi");
      deleteQueryStringParam("ddsb");
      deleteQueryStringParam("ddsr");
      deleteQueryStringParam("priv");
      deleteQueryStringParam("factor_id");
    },
    determineSquadNamesFromSquadIds() {
      const selectedSquads = this.cachedSquads.filter(
        (squad) =>
          this.squadIds.includes(String(squad.id)) ||
          this.squadIds.includes(squad.id),
      );

      this.squadNamesFromSquadIds = selectedSquads.map((squad) => squad.name);

      // Loading directly to modal from URL cached squads are empty, so wait for event
      if (this.squadIds.length != 1) {
        this.showTeamBreakdown = true;
      } else {
        this.showTeamBreakdown = this.cachedSquads.find(
          (squad) => squad.id == this.squadIds[0],
        )?.is_parent;
      }
    },
    get breakdownItems() {
      let items;

      if (this.drilldownData.team_breakdown) {
        items = this.drilldownData.team_breakdown.data.result_items.sort(
          sortFunc(this.drilldownSortBy, this.compareTo, {
            orgScore: this.selectedFactor()?.org_score,
            reverse: this.drilldownSortReverse,
          }),
        );

        const fieldsWithReversibleSortFunctions = [
          "score",
          "calculatedComparison",
          "dxiComparison",
          "priority",
        ];

        if (
          !fieldsWithReversibleSortFunctions.includes(this.drilldownSortBy) &&
          this.drilldownSortReverse
        ) {
          return items.reverse();
        }

        return items;
      }

      return [];
    },
    get driverItems() {
      const items = this.resultItems
        .filter((i) => i.current_snapshot)
        .sort(sortFunc(this.sortBy, this.compareTo));

      if (this.sortReverse) {
        return items.reverse();
      }

      return items;
    },
    get workflowItems() {
      if (this.drilldownData.related_workflows) {
        return this.drilldownData.related_workflows.data
          .workflow_score_and_distribution;
      }

      return [];
    },
    get datacloudChartItems() {
      if (this.drilldownData.related_workflows) {
        return this.drilldownData.related_workflows.data.datacloud_charts;
      }

      return [];
    },
    workflowComparisonValue(workflow) {
      if (this.compareTo == "vs_50th")
        return workflow.benchmark_50 == null
          ? null
          : Math.round(workflow.score - workflow.benchmark_50);
      if (this.compareTo == "vs_75th")
        return workflow.benchmark_75 == null
          ? null
          : Math.round(workflow.score - workflow.benchmark_75);
      if (this.compareTo == "vs_90th")
        return workflow.benchmark_90 == null
          ? null
          : Math.round(workflow.score - workflow.benchmark_90);
      if (this.compareTo == "vs_prev")
        return workflow.prev_percentage == null
          ? null
          : Math.round(workflow.score - workflow.prev_percentage);
      if (this.compareTo == "vs_org")
        return Math.round(workflow.score - workflow.org_score);
    },
    get maxDriverScore() {
      const arr = this.driverItems.map((i) => i.score);

      return Math.max(...arr);
    },
    get maxPriority() {
      const arr = this.driverItems.map((i) => i.priority);

      return Math.max(...arr);
    },
    targetedPositions(workflow) {
      if (workflow.target_direction == "u")
        return arrayRange(0, workflow.target_position, 1);

      return arrayRange(
        workflow.target_position,
        maxBehavioralQuestions - 1,
        1,
      ).reverse();
    },
    percentageWidthForPosition(workflow, position) {
      return `width: ${this.workflowPercentageOfResponses(
        workflow,
        position,
        "count",
      )}`;
    },
    workflowPercentageOfResponses(workflow, position) {
      if (workflow["count"] === 0) return "0%";

      return `${Math.round(
        (100 * workflow[targetPositionCountMap.get(position)]) /
          workflow["count"],
      )}%`;
    },
    upDirection(workflow) {
      return workflow.target_direction == "u";
    },
    nonTargetedPositions(workflow) {
      if (workflow.target_direction == "u")
        return arrayRange(
          workflow.target_position + 1,
          maxBehavioralQuestions - 1,
          1,
        );

      return arrayRange(0, workflow.target_position - 1, 1).reverse();
    },
    isSortOrder(order) {
      return this.sortBy == order;
    },
    isDrilldownSortOrder(order) {
      return this.drilldownSortBy == order;
    },
    changeComparison(compareTo) {
      this.$store.snapshotResultFilters.setCompareTo(compareTo);
      if (this.compareTo == "vs_prev") this.fetchAll();
      this.calculateDxi();
      this.setDxiComparisonValues();
      // Update any trends comparison data if it's showing
      if (this.selectedDrilldownTab == "overview" && this.drilldownModalOpen) {
        this.renderDriverTrendChart();
      }
      if (this.selectedDrilldownTab == "overview" && this.dxiModalOpen) {
        this.renderDxiTrendChart();
      }
    },
    changeSortBy(sortBy) {
      if (this.sortBy == sortBy) {
        this.$store.snapshotResultFilters.sortReverse = !this.sortReverse;

        setQueryStringParam("sr", this.sortReverse);
        return;
      }

      this.$store.snapshotResultFilters.sortBy = sortBy;
      this.$store.snapshotResultFilters.sortReverse = false;

      setQueryStringParam("sb", sortBy);
      setQueryStringParam("sr", false);
    },
    changeDrilldownSortBy(sortBy) {
      if (this.drilldownSortBy == sortBy) {
        this.$store.snapshotResultFilters.drilldownSortReverse =
          !this.drilldownSortReverse;

        setQueryStringParam("ddsr", this.drilldownSortReverse);
        return;
      }

      this.$store.snapshotResultFilters.drilldownSortBy = sortBy;
      this.$store.snapshotResultFilters.drilldownSortReverse = false;

      setQueryStringParam("ddsb", sortBy);
      setQueryStringParam("ddsr", false);
    },
    changeSquadIds(squadIds) {
      this.$store.snapshotResultFilters.squadIds = squadIds;
      this.determineSquadNamesFromSquadIds();
      setQueryStringParam("squad_ids", squadIds);

      this.fetchAll();
    },
    changeTagIds(tagIds) {
      this.$store.snapshotResultFilters.tagIds = tagIds;
      setQueryStringParam("tag_ids", tagIds);

      this.fetchAll();
    },
    fetchAll() {
      // fetchComments sets this to false as well, but is not called until other data has been loaded
      this.commentsLoaded = false;
      this.allComments = [];
      this.commentIds = new Set();

      return this.fetchResultItems()
        .then(() => {
          // If filters have changed then reset the comments
          this.fetchComments(true);
          this.determineSquadNamesFromSquadIds();
        })
        .catch((error) => {
          console.error("Error fetching items", error);
        });
    },
    handleScroll() {
      const { scrollTop, offsetHeight, scrollHeight } =
        this.$refs.allCommentsContainer;

      const fetchMore = scrollTop + offsetHeight + 20 > scrollHeight;

      if (fetchMore && this.hasMoreComments) {
        this.fetchComments();
      }
    },
    fetchComments(resetComments = false) {
      this.commentsLoaded = false;
      this.commentPage = resetComments ? 1 : this.commentPage + 1;

      const paramsObj = {
        page: this.commentPage,
        limit: COMMENT_PAGE_SIZE,
        squad_ids: this.squadIds.join(","),
        tag_ids: this.tagIds.join(","),
        branch: this.$store.snapshotResultFilters.branch,
      };

      const queryParams = objectToQueryString(paramsObj);

      let url = `${baseUrl}/comments?${queryParams}`;

      return fetch(url)
        .then((resp) => resp.json())
        .then((data) => {
          if (resetComments) {
            this.allComments = data.slice(0, COMMENT_PAGE_SIZE);
            this.commentIds = new Set(
              this.allComments.map((comment) => comment.id),
            );
          } else {
            const commentsToAppend = data
              .slice(0, COMMENT_PAGE_SIZE)
              .filter((comment) => {
                return !this.commentIds.has(comment.id);
              });
            this.allComments = this.allComments.concat(commentsToAppend);
            commentsToAppend.forEach((comment) =>
              this.commentIds.add(comment.id),
            );
          }

          this.hasMoreComments = data.length > COMMENT_PAGE_SIZE;

          this.commentsLoaded = true;
        });
    },
    generateDxiTrendComparisonValues() {
      let benchmarkScore;

      if (this.compareTo == "vs_50th") {
        benchmarkScore = this.dxi50;
      } else if (this.compareTo == "vs_75th") {
        benchmarkScore = this.dxi75;
      } else if (this.compareTo == "vs_90th") {
        benchmarkScore = this.dxi90;
      } else if (this.compareTo == "vs_org") {
        benchmarkScore = this.orgDxiScore;
      }

      return this.trends.values.map((item) => {
        return {
          date: item.date,
          label: comparisonOptionsMap.get(this.compareTo),
          value: benchmarkScore,
        };
      });
    },
    renderDxiTrendChart() {
      const elChart = document.getElementById(`dxi-trends-chart`);
      if (!elChart) return;

      elChart.innerHTML = null;
      const benchmark = "vs_prev";
      const minVal = Math.min(...this.trends.values.map((v) => v.value));
      const maxVal = Math.max(...this.trends.values.map((v) => v.value));

      const trendSize = this.trends.values.length;
      this.xEnd = this.trends.values[trendSize - 1]?.label;
      // Avoid using 'this' in passing in function for the tooltip callback, get the string explicitly
      const heading = "Developer experience index";

      const attrs = {
        elChart: elChart,
        startDate: this.xStart,
        endDate: this.trends.values[trendSize - 1]?.date,
        tooltipHtml(d, cd) {
          const params = {
            heading: heading,
            d: {
              label: d.label,
              value: d.value,
              formattedValue: d.value,
            },
          };

          if (cd) {
            params.cd = {
              label: cd.label,
              value: cd.value,
              formattedValue: cd.value,
            };
          }

          return chartTooltipHtml.default(params);
        },
        yAxisMin: minVal * 0.8,
        yAxisMax: maxVal * 1.2,
        showXAxisTicks: false,
        values: this.trends.values,
      };

      if (
        ["vs_50th", "vs_75th", "vs_90th", "vs_org"].includes(this.compareTo)
      ) {
        attrs.comparisonValues = this.generateDxiTrendComparisonValues();
      }

      if (this.compareTo == "vs_prev") {
        attrs.comparisonValues = generateComparisonValues(
          this.trends,
          benchmark,
        );
      }

      return new LineChart(attrs);
    },
    fetchTabDataBackground(tab, url) {
      if (!this.selectedFactor) return;

      return fetch(`${url}${this.queryParams()}`)
        .then((resp) => resp.json())
        .then((data) => {
          this.drilldownData[tab] = { data };
        });
    },
    fetchTab(tab, url, modalView) {
      if (tab == "comments") {
        const privParam = getQueryStringParams().get("priv");
        if (privParam == null) {
          setQueryStringParam("priv", false);
        }
      } else deleteQueryStringParam("priv");

      if (!this.drilldownData[tab]) {
        this.tabDataLoaded = false;
      }

      this.selectedDrilldownTab = tab;
      setQueryStringParam("ddt", tab);

      const fullUrl = `${url}${this.queryParams()}`;

      if (!this.selectedFactor) return;

      return fetch(fullUrl)
        .then((resp) => resp.json())
        .then((data) => {
          if (tab == "overview" && modalView == "driver") {
            // Fallback to null as undefined will not render properly
            data.trends[0].factor["vs_50th"] =
              data.benchmark_factor?.p_50 || null;
            data.trends[0].factor["vs_75th"] =
              data.benchmark_factor?.p_75 || null;
            data.trends[0].factor["vs_90th"] =
              data.benchmark_factor?.p_90 || null;
          }

          if (tab == "team_breakdown" && data.benchmark_factor) {
            const benchmarkFactor = data.benchmark_factor;

            data.result_items.forEach((item) => {
              item["benchmark_50"] = benchmarkFactor["p_50"];
              item["benchmark_75"] = benchmarkFactor["p_75"];
              item["benchmark_90"] = benchmarkFactor["p_90"];
            });
          }

          if (
            tab == "related_workflows" &&
            data.workflow_score_and_distribution
          ) {
            data.workflow_score_and_distribution.forEach((workflow) => {
              const benchmarkBehavioralQuestion =
                data.benchmark_behavioral_questions[workflow["id"]];

              if (benchmarkBehavioralQuestion) {
                workflow["benchmark_50"] = benchmarkBehavioralQuestion["p_50"];
                workflow["benchmark_75"] = benchmarkBehavioralQuestion["p_75"];
                workflow["benchmark_90"] = benchmarkBehavioralQuestion["p_90"];
              }
            });

            data.workflow_trends.forEach((trendRow) => {
              const benchmarkBehavioralQuestion =
                data.benchmark_behavioral_questions[trendRow.workflow["id"]];

              if (benchmarkBehavioralQuestion) {
                trendRow.workflow["benchmark_50"] =
                  benchmarkBehavioralQuestion["p_50"];
                trendRow.workflow["benchmark_75"] =
                  benchmarkBehavioralQuestion["p_75"];
                trendRow.workflow["benchmark_90"] =
                  benchmarkBehavioralQuestion["p_90"];
              }
            });
          }

          this.drilldownData[tab] = { data };
          this.tabDataLoaded = true;
          this.renderTabDetails(tab, data, modalView);
        })
        .catch((error) => console.log("Error fetching tab", tab, error));
    },
    xStartLabel() {
      const date = new Date(this.xStart);
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();

      return `${month} ${year}`;
    },
    renderDriverTrendChart() {
      const elChart = document.getElementById(`driver-trends-chart`);
      if (!elChart) return;

      elChart.innerHTML = null;
      const benchmark = this.compareTo;
      const minVal = Math.min(
        ...this.trends.values
          .map((v) => v.value)
          .concat(this.trends.factor.vs_50th),
      );
      const maxVal = Math.max(
        ...this.trends.values
          .map((v) => v.value)
          .concat(this.trends.factor.vs_90th),
      );

      const trendSize = this.trends.values.length;
      this.xEnd = this.trends.values[trendSize - 1]?.label;
      // Avoid using 'this' in passing in function for the tooltip callback, get the string explicitly
      const heading = this.trends.factor.name;

      const attrs = {
        elChart: elChart,
        startDate: this.xStart,
        endDate: this.trends.values[trendSize - 1]?.date,
        tooltipHtml(d, cd) {
          const params = {
            heading: heading,
            d: {
              label: d.label,
              value: d.value,
              formattedValue: d.value,
            },
          };

          if (cd) {
            params.cd = {
              label: cd.label,
              value: cd.value,
              formattedValue: cd.value,
            };
          }

          return chartTooltipHtml.default(params);
        },
        yAxisMin: minVal * 0.8,
        yAxisMax: maxVal * 1.2,
        showXAxisTicks: false,
        values: this.trends.values,
      };

      if (
        benchmark &&
        (this.trends.factor[benchmark] || benchmark === "vs_prev")
      ) {
        attrs.comparisonValues = generateComparisonValues(
          this.trends,
          benchmark,
        );
      }

      if (benchmark == "vs_org") {
        attrs.comparisonValues = generateOrgComparisonValues(
          this.trends,
          this.selectedFactor()?.score - this.selectedFactorComparison(),
        );
      }

      return new LineChart(attrs);
    },
    renderDriverDistributionChart() {
      if (!this.hasMultipleTrendValues) return;
      const elChart = document.getElementById(`driver-distribution-chart`);
      if (!elChart) return;

      elChart.innerHTML = null;
      const benchmark = this.compareTo;
      const minVal = Math.min(
        ...this.trends.values
          .map((v) => v.value)
          .concat(this.trends.factor.vs_50th),
      );
      const maxVal = Math.max(
        ...this.trends.values
          .map((v) => v.value)
          .concat(this.trends.factor.vs_90th),
      );

      const trendSize = this.trends.values.length;
      this.xEnd = this.trends.values[trendSize - 1]?.label;
      // Avoid using 'this' in passing in function for the tooltip callback, get the string explicitly

      const colors = ["#FCA5A5", "#FDBA74", "#FCD34D", "#BEF264", "#86EFAC"];
      let series;

      if (this.selectedFactorIs5Point) {
        series = [
          {
            key: "one_count",
            name: "Never",
            color: colors[0],
            counts: [],
          },
          {
            key: "two_count",
            name: "Rarely",
            color: colors[1],
            counts: [],
          },
          {
            key: "three_count",
            name: "Sometimes",
            color: colors[2],
            counts: [],
          },
          {
            key: "four_count",
            name: "Very often",
            color: colors[3],
            counts: [],
          },
          {
            key: "five_count",
            name: "Always",
            color: colors[4],
            counts: [],
          },
        ];
      } else {
        series = [
          {
            key: "one_count",
            name: "Bad",
            color: colors[0],
            counts: [],
          },
          {
            key: "two_count",
            name: "So-so",
            color: colors[2],
            counts: [],
          },
          {
            key: "three_count",
            name: "Good",
            color: colors[4],
            counts: [],
          },
        ];
      }

      let stackedAreaValues = {
        series: series,
        dates: [],
      };

      // Formatting the trend data to match the required format the stacked area chart needs
      this.trends.values.forEach((val) => {
        stackedAreaValues.dates.push(val.date);

        stackedAreaValues.series.forEach((breakdownValue) => {
          breakdownValue.counts.push(val.breakdown[breakdownValue.key]);
        });
      });

      const attrs = {
        elChart: elChart,
        startDate: this.xStart,
        endDate: this.trends.values[trendSize - 1]?.date,
        tooltipHtml(d, cd) {
          const params = {
            heading: new Intl.DateTimeFormat("en-US", {
              month: "short",
              year: "numeric",
            }).format(d),
            rows: cd
              .map((r) => ({
                label: r.name,
                value: r.count,
                formattedValue: Math.round(r.percentage * 100) + "%",
                color: r.color,
              }))
              .reverse(),
          };

          return chartTooltipHtml.default(params);
        },
        yAxisMin: minVal * 0.8,
        yAxisMax: maxVal * 1.2,
        showXAxisTicks: false,
        values: stackedAreaValues,
      };

      if (
        benchmark &&
        (this.trends.factor[benchmark] || benchmark === "vs_prev")
      ) {
        attrs.comparisonValues = generateComparisonValues(
          this.trends,
          benchmark,
        );
      }

      return new StackedAreaChart(attrs);
    },
    renderTabDetails(tab, data, modalView) {
      if (modalView == "driver") {
        if (tab == "overview" && this.responseCount >= minResponseThreshold) {
          this.trends = data.trends[0];
          this.xStart = this.trends.values[0].date;
          this.renderDriverTrendChart();
          this.renderDriverDistributionChart();
        }
        if (tab == "team_breakdown") {
          this.calculateMaxTeamPriority();
          this.calculateMaxTeamScoreForDriver();
        }
        if (tab == "priority") {
          this.calculateRankChange();
          this.renderBumpChart();
        }
        if (tab == "related_workflows") {
          this.xStart = data.x_start;
          this.renderRelatedWorkflowsCharts();
        }
      }

      if (modalView == "dxi") {
        if (tab == "overview") {
          this.trends = data.trends;
          this.xStart = data.x_start;
          this.renderDxiTrendChart();
        }
        if (tab == "team_breakdown") {
          // While DXI is not a driver, it has the same field
          this.calculateMaxTeamScoreForDriver();
        }
      }
    },
    renderRelatedWorkflowsCharts() {
      if (!this.drilldownData.related_workflows?.data?.workflow_trends) return;

      const workflowData = this.drilldownData.related_workflows.data;

      workflowData.workflow_score_and_distribution.forEach((workflow) => {
        const trendData = workflowData.workflow_trends.find((trend) => {
          return trend.workflow.id == workflow.id;
        });

        this.renderWorkflowTrendChart(workflow, trendData);
        this.renderWorkflowDistributionChart(workflow, trendData);
      });
    },
    workflowTrendEnd(workflow) {
      if (!this.drilldownData.related_workflows?.data?.workflow_trends) return;

      const trendData =
        this.drilldownData.related_workflows.data.workflow_trends.find(
          (trend) => {
            return trend.workflow.id == workflow.id;
          },
        );

      if (!trendData) return;

      return trendData.values[trendData.values.length - 1].label;
    },
    hasMultipleTrendValuesForWorkflow(workflow) {
      if (!this.drilldownData.related_workflows?.data?.workflow_trends) return;

      const trendData =
        this.drilldownData.related_workflows.data.workflow_trends.find(
          (trend) => {
            return trend.workflow.id == workflow.id;
          },
        );

      if (!trendData) return;

      return trendData.values?.length > 1;
    },
    renderWorkflowTrendChart(workflow, trendData) {
      if (!trendData) return;

      const chartEl = document.getElementById(`workflow-${workflow.id}-chart`);
      if (!chartEl) return;

      chartEl.innerHTML = null;

      const benchmark = this.compareTo;
      const minVal = Math.min(
        ...trendData.values.map((v) => v.value).concat(workflow.benchmark_50),
      );
      const maxVal = Math.max(
        ...trendData.values.map((v) => v.value).concat(workflow.benchmark_90),
      );
      const xStart = this.xStart;
      const xEnd = trendData.values[trendData.values.length - 1].date;

      const attrs = {
        elChart: chartEl,
        startDate: xStart,
        endDate: xEnd,
        tooltipHtml(d, cd) {
          const params = {
            heading: workflow.name,
            d: {
              label: d.label,
              value: d.value,
              formattedValue: `${d.value}%`,
            },
          };

          if (cd) {
            params.cd = {
              label: cd.label,
              value: cd.value,
              formattedValue: `${cd.value}%`,
            };
          }

          return chartTooltipHtml.default(params);
        },
        yAxisMin: minVal * 0.8,
        yAxisMax: maxVal * 1.2,
        showXAxisTicks: false,
        values: trendData.values,
      };

      if (
        benchmark &&
        (workflow[benchmarkDiffConversions.get(benchmark)] ||
          benchmark === "vs_prev")
      ) {
        attrs.comparisonValues = generateComparisonValues(
          trendData,
          benchmarkDiffConversions.get(benchmark),
          "workflow",
        );
      }
      if (benchmark == "vs_org") {
        attrs.comparisonValues = generateOrgComparisonValues(
          trendData,
          Math.round(workflow.org_score),
        );
      }

      return new LineChart(attrs);
    },
    renderWorkflowDistributionChart(workflow, trendData) {
      let chartElement = document.getElementById(
        `workflow-${workflow.id}-distribution-chart`,
      );

      if (!workflow || !trendData || !chartElement) return;

      chartElement.innerHTML = null;

      const benchmark = this.compareTo;
      const minVal = Math.min(
        ...trendData.values.map((v) => v.value).concat(workflow.benchmark_50),
      );
      const maxVal = Math.max(
        ...trendData.values.map((v) => v.value).concat(workflow.benchmark_90),
      );
      const xEnd = trendData.values[trendData.values.length - 1].date;
      const fiveColors = [
        "#86EFAC",
        "#BEF264",
        "#FCD34D",
        "#FDBA74",
        "#FCA5A5",
      ];
      const binaryColors = [
        "#86EFAC", // Green for "Yes"
        "#FCD34D", // Yellow for "No"
      ];

      let options = JSON.parse(trendData.values[0]?.options);
      let needsReversing = workflow?.target_direction === "d";
      if (needsReversing) options = options.reverse();

      const colors = options.length === 2 ? binaryColors : fiveColors;

      let stackedAreaValues = { series: [], dates: [] };

      options.forEach((opt, idx) => {
        let indexToUse = idx;
        // Go backwards through the list of options to ensure ordering matches the distribution chart above
        if (needsReversing) indexToUse = options.length - (idx + 1);

        stackedAreaValues.series.push({
          name: opt,
          color: colors[idx], // This will use the appropriate color based on the number of options
          counts: [],
          key: `${numberToSpelling(indexToUse)}_position_count`,
        });
      });

      // Formatting the trend data to match the required format the stacked area chart needs
      trendData.values.forEach((val) => {
        stackedAreaValues.dates.push(val.date);

        stackedAreaValues.series.forEach((breakdownValue) => {
          breakdownValue.counts.push(val.breakdown[breakdownValue.key]);
        });
      });

      // NOTE: reversing here so that the stacks matches the order of the distribution chart above
      stackedAreaValues.series = stackedAreaValues.series.reverse();

      const attrs = {
        elChart: chartElement,
        startDate: this.xStart,
        endDate: xEnd,
        tooltipHtml(d, cd) {
          const params = {
            heading: new Intl.DateTimeFormat("en-US", {
              month: "short",
              year: "numeric",
            }).format(d),
            rows: cd
              .map((r) => ({
                label: r.name,
                value: r.count,
                formattedValue: Math.round(r.percentage * 100) + "%",
                color: r.color,
              }))
              .reverse(),
          };

          return chartTooltipHtml.default(params);
        },
        yAxisMin: minVal * 0.8,
        yAxisMax: maxVal * 1.2,
        showXAxisTicks: false,
        values: stackedAreaValues,
      };

      if (
        benchmark &&
        (workflow[benchmarkDiffConversions.get(benchmark)] ||
          benchmark === "vs_prev")
      ) {
        attrs.comparisonValues = generateComparisonValues(
          trendData,
          benchmarkDiffConversions.get(benchmark),
          "workflow",
        );
      }

      return new StackedAreaChart(attrs);
    },

    calculateMaxTeamPriority() {
      const arr = this.breakdownItems.map((i) => i.priority);

      this.maxTeamPriority = Math.max(...arr);
    },
    calculateMaxTeamScoreForDriver() {
      const arr = this.breakdownItems.map((i) => i.score);

      this.maxTeamScoreForDriver = Math.max(...arr);
    },
    currentRank() {
      if (this.drilldownData.priority?.data) {
        const ranks = this.drilldownData.priority.data.series.filter(
          (i) => i.id == this.targetedFactor,
        )[0].ranks;

        return ranks[ranks.length - 1];
      }
    },
    previousRank() {
      if (this.drilldownData.priority?.data) {
        const ranks = this.drilldownData.priority.data.series.filter(
          (i) => i.id == this.targetedFactor,
        )[0].ranks;

        return ranks[ranks.length - 2];
      }
    },
    get targetedFactorPriorityVotes() {
      return this.selectedFactor()?.priority;
    },
    calculateRankChange() {
      if (!this.previousRank()) return;

      const amount = this.currentRank() - this.previousRank();
      this.selectedFactorRankChange = amount;
    },
    renderBumpChart() {
      const elChart = document.getElementById(`bumpchart`);
      if (!elChart) return;

      elChart.innerHTML = null;

      new BumpChart({
        elChart: document.querySelector("#bumpchart"),
        data: this.drilldownData.priority.data,
        focusedOn: this.viewAllDrivers ? null : this.selectedFactor()?.name,
      });
    },
    queryParams() {
      let paramObj = {
        ct: this.compareTo,
        prev_ss: this.previousSnapshotId,
        squad_ids: `${this.squadIds.join(",")}`,
        tag_ids: `${this.tagIds.join(",")}`,
        factor_id: this.targetedFactor,
        hg: this.$store.snapshotResultFilters.hierarchyGroup.encoded_id,
        branch: this.$store.snapshotResultFilters.branch,
      };

      if (this.selectedDrilldownTab == "comments") {
        paramObj.priv = this.privateCommentsVisible;
      }

      return `?${objectToQueryString(paramObj)}`;
    },
    calculateDxiComparison(item) {
      if (this.compareTo == "vs_50th") {
        return item.score - this.dxi50;
      } else if (this.compareTo == "vs_75th") {
        return item.score - this.dxi75;
      } else if (this.compareTo == "vs_90th") {
        return item.score - this.dxi90;
      } else if (this.compareTo == "vs_prev") {
        return item.previous_score != null
          ? item.score - item.previous_score
          : null;
      }
    },
    calculateItemComparison(item) {
      if (this.compareTo == "vs_50th" && item.benchmark_50) {
        return item.score - item.benchmark_50;
      } else if (this.compareTo == "vs_75th" && item.benchmark_50) {
        return item.score - item.benchmark_75;
      } else if (this.compareTo == "vs_90th" && item.benchmark_50) {
        return item.score - item.benchmark_90;
      } else if (this.compareTo == "vs_prev") {
        return item.previous_score != null
          ? item.score - item.previous_score
          : null;
      } else if (this.compareTo == "vs_org") {
        return item.score - this.selectedFactor()?.org_score;
      }

      return null;
    },
    get atlasStrategies() {
      if (this.drilldownData.recommendations?.data) {
        return this.drilldownData.recommendations.data.strategies;
      }

      return [];
    },
    get atlasArticle() {
      if (this.drilldownData.recommendations?.data) {
        return this.drilldownData.recommendations.data.article;
      }

      return null;
    },
    get filtersApplied() {
      return (
        this.$store.snapshotResultFilters.tagIds.length ||
        this.$store.snapshotResultFilters.squadIds.length ||
        this.$store.snapshotResultFilters.hierarchyGroup
      );
    },
    fetchResultItems() {
      this.resultItemsLoaded = false;

      let url = `${baseUrl}/items`;

      const paramsObj = {
        ct: this.compareTo,
        prev_ss: this.previousSnapshotId,
        squad_ids: this.squadIds.join(","),
        tag_ids: this.tagIds.join(","),
        branch: this.$store.snapshotResultFilters.branch,
      };

      const queryParams = objectToQueryString(paramsObj);

      return fetch(`${url}?${queryParams}`)
        .then((resp) => resp.json())
        .then((data) => {
          if (typeof data.benchmark_factors == "object") {
            data.result_items.forEach((row) => {
              const benchmarkFactor = data.benchmark_factors[row["id"]];

              if (
                benchmarkFactor &&
                row["score"] !== null &&
                row["score"] !== undefined
              ) {
                row["benchmark_50"] = benchmarkFactor["p_50"];
                row["benchmark_75"] = benchmarkFactor["p_75"];
                row["benchmark_90"] = benchmarkFactor["p_90"];

                row["vs_50th"] = row["score"] - benchmarkFactor["p_50"];
                row["vs_75th"] = row["score"] - benchmarkFactor["p_75"];
                row["vs_90th"] = row["score"] - benchmarkFactor["p_90"];
              }
            });
          }

          this.resultItems = data.result_items;
          this.responseCount = data.response_count;
          this.recipientCount = data.recipient_count;
          this.calculateDxi();
          this.setDxiComparisonValues();

          this.resultItemsLoaded = true;
        })
        .catch((error) => {
          console.error("Error fetching items", error);
        });
    },
    hidePrivateComments() {
      const selectedItem = drilldownTabs.find(
        (t) => t.tab == this.selectedDrilldownTab,
      );

      this.privateCommentsVisible = false;
      setQueryStringParam("priv", false);

      this.fetchTab(this.selectedDrilldownTab, selectedItem.url, "driver");
    },
    showPrivateComments() {
      const selectedItem = drilldownTabs.find(
        (t) => t.tab == this.selectedDrilldownTab,
      );

      this.privateCommentsVisible = true;
      setQueryStringParam("priv", true);
      this.fetchTab(this.selectedDrilldownTab, selectedItem.url, "driver");
    },
    toggleViewAllDrivers() {
      this.viewAllDrivers = !this.viewAllDrivers;
      this.renderBumpChart();
    },
    hideSearch() {
      this.$refs.searchField?.blur();
      this.showCommentsSearch = false;
    },
    showSearch() {
      this.showCommentsSearch = true;
      setTimeout(() => {
        this.$refs.searchField?.focus();
      }, 50);
    },
    shouldBlur() {
      if (this.showCommentsSearch) {
        if (this.commentSearchTerm.trim() == "") {
          this.$refs.searchField?.blur();
          this.showCommentsSearch = false;
        }
      }
    },
    clearSearchTerm() {
      this.commentSearchTerm = "";
      this.commentSearchResults = [];
      this.searchCommentsLoaded = false;
    },
    performCommentSearch() {
      this.searchCommentsLoaded = false;
      const finalTerm = this.commentSearchTerm.trim();

      if (finalTerm.length > 2) {
        const queryParamObject = {
          term: finalTerm,
          factor_id: targetedFactor,
          branch: this.$store.snapshotResultFilters.branch,
          squad_ids: this.squadIds.join(","),
          tag_ids: this.tagIds.join(","),
        };

        const fullUrl = `${baseUrl}/comments/search?${objectToQueryString(queryParamObject)}`;

        return fetch(fullUrl)
          .then((resp) => resp.json())
          .then((data) => {
            this.commentSearchResults = data;
            this.searchCommentsLoaded = true;
          })
          .catch((error) => {
            console.error("Error fetching items", error);
          });
      } else {
        this.commentSearchResults = [];
      }
    },
    refetchTeamBreakdown() {
      if (this.dxiModalOpen) {
        const selectedItem = dxiDrilldownTabs.find(
          (t) => t.tab == this.selectedDrilldownTab,
        );
        this.fetchTab(selectedItem.tab, selectedItem.url, "dxi");
      } else {
        const selectedItem = drilldownTabs.find(
          (t) => t.tab == this.selectedDrilldownTab,
        );
        if (selectedItem?.url) {
          this.fetchTab(this.selectedDrilldownTab, selectedItem.url, "driver");
        }
      }
    },
    get exportCommentsUrlWithParams() {
      return `${this.exportCommentsUrl}${this.queryParams()}`;
    },
  };
}
