import { postData, isEmail, getQueryStringParams } from "../../shared/utils";

const TREE_TYPES = {
  TEAM: "TEAM",
  MANAGEMENT: "MANAGEMENT",
};

export function adminOrgCsvs(args) {
  const { account } = args;

  return {
    loading: false,
    showInstructions: false,
    runningImports: args.runningImports,
    completedImports: args.completedImports,
    account,
    isDragging: false,
    hasError: false,
    file: null,
    headers: [],
    rows: [],
    sampledRows: [],
    preprocessedTeams: [],
    fallbacksAdded: 0,
    activeImportId: null,
    newImport:
      getQueryStringParams().get("new") ||
      (!args.runningImports.length && !args.completedImports.length),
    showCustomSettings: getQueryStringParams().get("custom_settings"),
    progress: 0,
    progressPayload: {},
    fields: [
      { key: "email", label: "Contributor email" },
      { key: "team_name", label: "Team name" },
      { key: "mgr_name", label: "Manager name" },
      { key: "mgr_email", label: "Team lead email" },
      { key: "parent_team", label: "Parent team name" },
      { key: "parent_team_dri", label: "Parent team lead email" },
      { key: "grandparent_team", label: "Grandparent team name" },
      { key: "grandparent_team_dri", label: "Grandparent Team lead email" },
      // { key: "director_email", label: "Director email" },
      // { key: "vp_email", label: "VP email" },
    ],
    selectedColumns: {
      name: "name",
      email: "email",
      team_name: "direct_team",
      mgr_name: "manager",
      mgr_email: "manager_email",
      parent_team: "parent_team",
      parent_team_dri: "parent_team_dri",
      grandparent_team: "grandparent_team",
      grandparent_team_dri: "grandparent_team_dri",
    },
    showPreview: false,
    teamsPreview: {},
    squads: [],
    treeType: TREE_TYPES.TEAM, // "MANAGEMENT" or "TEAM"
    treeTypes: Object.keys(TREE_TYPES),
    expandedSquadsIds: {},
    customSettings: {
      minGroupSize: 0,
      prunedTeams: [],
      associationsOnly: false,
      includeManagersAsOwnTeamMembers: false,
    },
    init() {},
    get columnsAreValid() {
      // TODO: More logic needed here for validity.
      let valid = false;

      const row = this.sampleRandomRows(1)?.[0];
      const email = row && row[this.selectedColumns["email"]];

      if (this.treeType == TREE_TYPES.TEAM) {
        if (
          isEmail(email) &&
          row[this.selectedColumns["team_name"]]?.length > 0
        )
          valid = true;
      } else if (this.treeType == TREE_TYPES.MANAGEMENT) {
        if (isEmail(email) && isEmail(row[this.selectedColumns["mgr_email"]]))
          valid = true;
      }

      return valid;
    },
    get treeSquads() {
      this.squads.map((s) => {
        return s;
      });

      const squadsToReturn = this.squads?.filter((s) => {
        const value =
          s.depth == 0 ||
          s.expanded == true ||
          this.expandedSquadsIds[s.parent_id] == true;

        return value;
      });

      return squadsToReturn;
    },
    formatDate(date) {
      const d = new Date(date);
      // Get the hours and minutes
      const hours = d.getHours();
      const minutes = d.getMinutes().toString().padStart(2, "0");
      const seconds = d.getSeconds().toString().padStart(2, "0");

      // Determine AM/PM
      const ampm = hours >= 12 ? "pm" : "am";

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;

      // Format date using Intl.DateTimeFormat
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }).format(d);

      // Return the formatted string
      return `${formattedHours}:${minutes}:${seconds}${ampm} on ${formattedDate}`;
    },
    handleDrop(event) {
      this.hasError = false;
      event?.preventDefault();
      this.file = event.dataTransfer.files[0];
      this.isDragging = false;
      this.validateFile();
    },
    processFile() {
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const csvContent = e.target.result;
          this.parseCSV(csvContent);
        };
        reader.readAsText(this.file);
      }
    },
    validateFile() {
      this.errorMessage = "";
      if (this.file) {
        // Check the MIME type or extension
        const mimeType = this.file.type;
        const fileName = this.file.name;

        // Validate MIME type or file extension
        if (
          mimeType !== "text/csv" &&
          mimeType !== "application/vnd.ms-excel" &&
          !fileName.endsWith(".csv")
        ) {
          this.errorMessage = "Invalid file type. Please upload a CSV file.";
          this.hasError = true;
          this.file = null;
          return;
        }

        this.processFile(); // Process only if it's a valid CSV file
      }
    },
    async parseCSV(csvContent) {
      const Papa = await import("papaparse");
      Papa.parse(csvContent, {
        header: true,
        complete: (result) => {
          this.headers = result.meta.fields;
          this.rows = result.data;
          this.sampledRows = this.sampleRandomRows();
        },
        skipEmptyLines: true,
      });
    },
    sampleRandomRows(n = 20) {
      if (this.rows.length > 0) {
        // Shuffle the rows and take a random sample of 20 rows
        const shuffled = [...this.rows].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, n);
      }
    },
    preview() {
      const managersSet = new Set();

      const employees = this.rows.map((row) => {
        if (row["BUSINESS_TITLE"]?.includes("VP")) {
          managersSet.add(row[this.selectedColumns["mgr_email"]]);
        }

        return {
          name: row[this.selectedColumns["name"]],
          email: row[this.selectedColumns["email"]],
          team: row[this.selectedColumns["team_name"]],
          mgr_name: row[this.selectedColumns["mgr_name"]], // TODO: fallback logic here to traverse back through the fallbacks if they don't exist
          mgr_email: row[this.selectedColumns["mgr_email"]],
          parent_team: row[this.selectedColumns["parent_team"]],
          parent_team_dri: row[this.selectedColumns["parent_team_dri"]],
          grandparent_team: row[this.selectedColumns["grandparent_team"]],
          grandparent_team_dri:
            row[this.selectedColumns["grandparent_team_dri"]],
        };
      });

      if (
        this.treeType === TREE_TYPES.MANAGEMENT &&
        !this.selectedColumns["mgr_email"]
      ) {
        this.treeType = TREE_TYPES.TEAM;
      }

      // const preprocessedManagerDataPinterest = null//this.preprocessManagerTeams( employees, managersSet,);

      const pinterestHierarchy = null; //this.buildTeamHierarchy( preprocessedManagerDataPinterest,);

      this.preprocessedTeams = this.preprocessTeams(employees, managersSet);

      if (!pinterestHierarchy) {
        const result =
          this.treeType == TREE_TYPES.MANAGEMENT
            ? this.buildOrgHierarchy(employees)
            : this.buildTeamHierarchy(this.preprocessedTeams);
        this.teamsPreview = result;
        console.log(`result:`, result);
      } else {
        this.teamsPreview = pinterestHierarchy;
      }

      this.squads = this.newFlattenHierarchy(
        this.teamsPreview,
        this.preprocessedTeams,
      ); // May need a different flattening process based on the tree type
      this.showPreview = true;
    },
    buildOrgHierarchy(employees) {
      // Create a map of all employees by their email
      const employeeMap = new Map(employees.map((emp) => [emp.email, emp]));

      // Find the top-level manager(s)
      const topManagers = employees.filter(
        (emp) => !employeeMap.has(emp.mgr_email),
      );

      let teamIdCounter = 1;

      const hasTeamName = this.selectedColumns["team_name"];

      // Function to recursively build the hierarchy
      function buildHierarchy(manager, depth = 1, parent_id) {
        const temp_id = `T${teamIdCounter++}`;
        const subordinates = employees.filter(
          (emp) => emp.mgr_email === manager.email,
        );
        const subTeams = subordinates.map((sub) =>
          buildHierarchy(sub, depth + 1, temp_id),
        );

        const teamName = hasTeamName
          ? subTeams.length > 0
            ? manager.team
            : manager.team
          : manager.mgr_email;

        const obj = {
          name: manager.name,
          email: manager.email,
          mgr_email: manager.mgr_email,
          team: teamName,
          parent_team: manager.parent_team,
          temp_id: temp_id,
          parent_id: parent_id,
          is_parent: subTeams.length > 0,
          depth: depth,
          subTeams: subTeams,
        };

        return obj;
      }

      // Group top managers by team
      const teamGroups = topManagers.reduce((groups, manager) => {
        const team = manager.team;
        if (!groups[team]) {
          groups[team] = [];
        }
        groups[team].push(manager);
        return groups;
      }, {});

      const managerGroups = topManagers.reduce((groups, manager) => {
        const team = manager.mgr_email;
        if (!groups[team]) {
          groups[team] = [];
        }
        groups[team].push(manager);
        return groups;
      }, {});

      const groupsToMatch = hasTeamName ? teamGroups : managerGroups;

      // Build the hierarchy for each team
      const hierarchy = Object.entries(groupsToMatch).map(
        ([team, managers]) => {
          const temp_id = `T${teamIdCounter++}`;

          return {
            team: team,
            email: team,
            team_names: [
              ...new Set(managers.map((m) => m.parent_team).filter(Boolean)),
            ],
            depth: 0,
            temp_id: temp_id,
            is_parent: true,
            subTeams: managers.map((manager) =>
              buildHierarchy(manager, 1, temp_id),
            ),
          };
        },
      );

      return hierarchy.sort((a, b) => {
        // Sort by is_parent first
        if (a.is_parent !== b.is_parent) {
          return b.is_parent - a.is_parent;
        }
        // If is_parent is the same, sort alphabetically by team
        return a.team.localeCompare(b.team);
      });
    },
    newFlattenHierarchy(hierarchy, preprocessedTeams) {
      const flatArray = [];

      function flattenNode(node, parentId = null) {
        // Push the current node (excluding the subTeams) into the flat array
        // Lookup the node in preprocessedTeams and ensure the members list is the same
        const matchingTeam = preprocessedTeams.find(
          (preprocessedTeam) => preprocessedTeam.team === node.name,
        );
        let members = [];

        if (matchingTeam) {
          members = matchingTeam.members; // Use members from preprocessedTeam if found
        } else {
          members = node.members;
        }

        flatArray.push({
          name: node.name,
          email: node.email,
          mgr_email: node.mgr_email,
          team: node?.team,
          temp_id: node.temp_id,
          parent_id: parentId,
          is_parent: node.is_parent,
          depth: node.depth,
          members,
          parent_team_name: node.parent_team,
        });

        // Recursively flatten the subTeams (children)
        node.subTeams.forEach((child) => flattenNode(child, node.temp_id));
      }

      // Start flattening from the top-level nodes
      hierarchy.forEach((team) => flattenNode(team));

      return flatArray;
    },
    preprocessManagerTeams(employees, managersSet) {
      // Create a map to store teams with their members
      const teamMap = new Map();

      employees.forEach((emp) => {
        const teamKey = `${emp.team}-${emp.mgr_email}`;

        if (!teamMap.has(teamKey)) {
          // Add the team if it doesn't already exist
          teamMap.set(teamKey, {
            team: emp.team,
            mgr_email: emp.mgr_email,
            team_dri: emp.mgr_email,
            parent_team: emp.parent_team || null,
            parent_team_dri: emp.parent_team_dri || null,
            grandparent_team: emp.grandparent_team || null,
            grandparent_team_dri: emp.grandparent_team_dri || null,
            members: [],
          });
        }

        // Adding only direct IC's team
        if (!managersSet.has(emp.email)) {
          teamMap.get(teamKey).members.push(emp.email);
        }
      });

      // Second Pass: Assign parent_team based on mgr_email
      teamMap.forEach((teamValue) => {
        if (teamValue.mgr_email) {
          // Find the manager's employee record
          const manager = employees.find(
            (emp) => emp.email === teamValue.mgr_email,
          );
          if (manager && manager.team) {
            // Assign the manager's team as the parent_team
            teamValue.parent_team = manager.team;
          }
        }
      });

      // Convert the map values to an array and return
      return Array.from(teamMap.values());
    },
    preprocessTeams(employees) {
      // Create a map to store teams with their members
      const teamMap = new Map();

      employees.forEach((emp) => {
        if (!teamMap.has(emp.team)) {
          // Add the team if it doesn't already exist
          teamMap.set(emp.team, {
            team: emp.team,
            mgr_email: emp.mgr_email,
            team_dri: emp.mgr_email,
            parent_team: emp.parent_team || null,
            parent_team_dri: emp.parent_team_dri || null,
            grandparent_team: emp.grandparent_team || null,
            grandparent_team_dri: emp.grandparent_team_dri || null,
            members: [],
          });
        }
        // Adding only direct IC's team
        // if (!managersSet.has(emp.email)) { // I don't know if we want this logic.
        teamMap.get(emp.team).members.push(emp.email);
        // }
      });

      // Convert the map values to an array and return
      return Array.from(teamMap.values());
    },
    buildTeamHierarchy(teams) {
      // Step 1: Create a map for quick team lookup by team name
      const teamMap = new Map();
      teams.forEach((team) => {
        teamMap.set(`${team.team} ${team.mgr_email}`, team);
      });

      // Step 2: Preprocess to add missing parent teams based on grandparent_team
      const allTeams = [...teams]; // Clone the original teams array
      let addedNewTeam;

      do {
        addedNewTeam = false;
        // Iterate over a copy of allTeams to prevent infinite loops
        const currentTeams = [...allTeams];
        currentTeams.forEach((team) => {
          // Handle missing parent_team
          if (team.parent_team && !teamMap.has(team.parent_team)) {
            if (team.grandparent_team) {
              // Create a placeholder for the missing parent_team
              const parentTeam = {
                team: team.parent_team,
                mgr_email: team.parent_team_dri,
                parent_team: team.grandparent_team, // Link to grandparent_team
                parent_team_dri: team.grandparent_team_dri,
                grandparent_team: null, // Optional: set to null or derive if possible
                members: 0, // Default value; adjust as needed
              };
              // Add the new parent team only if it hasn't been added already
              if (!teamMap.has(parentTeam.team)) {
                allTeams.push(parentTeam);
                teamMap.set(parentTeam.team, parentTeam);
                addedNewTeam = true;
                console.log(`Added missing parent team: ${parentTeam.team}`);
              }

              // Additionally, ensure that the grandparent_team exists
              if (
                parentTeam.parent_team &&
                !teamMap.has(parentTeam.parent_team)
              ) {
                const grandparentTeam = {
                  team: parentTeam.parent_team,
                  mgr_email: parentTeam.parent_team_dri,
                  parent_team: null, // Assuming top-level if not specified
                  grandparent_team: null,
                  members: 0, // Default value; adjust as needed
                };
                if (!teamMap.has(grandparentTeam.team)) {
                  allTeams.push(grandparentTeam);
                  teamMap.set(grandparentTeam.team, grandparentTeam);
                  addedNewTeam = true;
                  console.log(
                    `Added grandparent team: ${grandparentTeam.team}`,
                  );
                }
              }
            } else {
              // If there's no grandparent_team, consider parent_team as a top-level team
              const parentTeam = {
                team: team.parent_team,
                mgr_email: team.parent_team_dri,
                parent_team: null,
                grandparent_team: null,
                members: 0,
              };
              if (!teamMap.has(parentTeam.team)) {
                allTeams.push(parentTeam);
                teamMap.set(parentTeam.team, parentTeam);
                addedNewTeam = true;
                console.log(`Added top-level parent team: ${parentTeam.team}`);
              }
            }
          }

          // Handle missing grandparent_team separately
          if (team.grandparent_team && !teamMap.has(team.grandparent_team)) {
            const grandparentTeam = {
              team: team.grandparent_team,
              mgr_email: team.grandparent_team_dri,
              parent_team: null, // Assuming top-level if not specified
              grandparent_team: null,
              members: 0, // Default value; adjust as needed
            };
            if (!teamMap.has(grandparentTeam.team)) {
              allTeams.push(grandparentTeam);
              teamMap.set(grandparentTeam.team, grandparentTeam);
              addedNewTeam = true;
              console.log(`Added grandparent team: ${grandparentTeam.team}`);
            }
          }
        });
      } while (addedNewTeam); // Continue until no new teams are added

      // Step 3: Identify Top-Level Teams
      const topTeamsSet = new Set();

      allTeams.forEach((team) => {
        if (!team.parent_team && team.team) {
          topTeamsSet.add(team.team);
        }
      });
      console.log(`topTeamsSet:`, topTeamsSet);

      if (topTeamsSet.size == 0) {
        // NOTE: Experiment for BNY. Using all the grandparent teams as the toplevel teams.
        allTeams.forEach((team) => {
          if (team.team && team.grandparent_team) {
            topTeamsSet.add(team.grandparent_team);
          }
        });
      }
      const topTeamsArray = Array.from(topTeamsSet);

      // Convert the Set to an Array of team objects
      const topTeams = topTeamsArray
        .map((teamName) => allTeams.find((team) => team.team === teamName))
        .filter((team) => team); // Filter out undefined if any

      let teamIdCounter = 1;
      const visited = new Set();
      const customSettings = this.customSettings;

      // Recursive function to build the hierarchy
      function buildHierarchy(team, depth = 0, parent_id = null) {
        const temp_id = `T${teamIdCounter++}`;

        // Detect circular references
        if (visited.has(team.team)) {
          console.warn(`Circular reference detected for team: ${team.team}`);
          return null; // Skip to prevent infinite loop
        }
        visited.add(team.team);

        // Find sub-teams based on parent_team
        const subTeams = allTeams
          .filter((sub) => sub.parent_team == team.team)
          .map((sub) => buildHierarchy(sub, depth + 1, temp_id))
          .filter((subTeam) => !!subTeam); // Remove nulls from circular references

        const members = team.members || [];
        if (team.mgr_email && customSettings?.includeManagersAsOwnTeamMembers) {
          members.push(team.mgr_email); // Add manager to members if not already included
        }
        return {
          name: team.team,
          team: team.team,
          parent_team: team.parent_team || null,
          mgr_email: team.mgr_email || null,
          temp_id: temp_id,
          parent_id: parent_id,
          is_parent: subTeams.length > 0,
          depth: depth,
          members,
          subTeams: subTeams.sort((a, b) => {
            if (a.is_parent !== b.is_parent) {
              return b.is_parent - a.is_parent;
            }
            return a.team.localeCompare(b.team);
          }),
        };
      }

      // Step 4: Build the hierarchy starting from top-level teams
      const hierarchy = topTeams
        .map((team) => buildHierarchy(team))
        .filter((team) => team);

      const minGroupSize = this.customSettings.minGroupSize;
      const prunedTeams = [];

      // Prune small leaf teams
      function pruneSmallTeams(node, parent = null) {
        if (!node) return null;

        // Process subTeams first
        if (node.subTeams && node.subTeams.length > 0) {
          node.subTeams = node.subTeams
            .map((subTeam) => pruneSmallTeams(subTeam, node))
            .filter((subTeam) => subTeam !== null);
        }

        // After processing subTeams, check if current node is a leaf
        const isLeaf = !node.subTeams || node.subTeams.length === 0;

        if (isLeaf && node.members.length < minGroupSize) {
          if (parent) {
            // Move members up to parent team
            if (Array.isArray(parent.members)) {
              parent.members = parent.members.concat(node.members);
            } else parent.members = node.members;

            // Record the pruned team
            prunedTeams.push({
              removedTeam: node.team,
              movedMemberCount: node.members.length,
              newSourceTeam: parent.team,
            });

            // Remove this node by returning null
            return null;
          } else {
            // If there's no parent, we cannot prune this team
            return node;
          }
        } else {
          // Update is_parent flag based on subTeams
          node.is_parent = node.subTeams && node.subTeams.length > 0;
          return node;
        }
      }

      // Bind the function to access 'this'
      const boundPruneSmallTeams = pruneSmallTeams.bind(this);

      let finalHierarchy;
      // Apply pruning to the hierarchy
      if (minGroupSize > 0) {
        finalHierarchy = hierarchy
          .map((team) => boundPruneSmallTeams(team))
          .filter((team) => team !== null);
      } else {
        finalHierarchy = hierarchy;
      }

      this.customSettings.prunedTeams = prunedTeams;

      const sortedHierarchy = finalHierarchy.sort((a, b) => {
        if (a.is_parent !== b.is_parent) {
          return b.is_parent - a.is_parent;
        }
        return a.team.localeCompare(b.team);
      });

      return sortedHierarchy;
    },
    toggleSquad(targetSquad) {
      this.squads = this.squads.map((squad) => {
        if (targetSquad.temp_id === squad.parent_id) {
          squad.parentExpanded = targetSquad.expanded;
        }

        if (targetSquad.temp_id === squad.temp_id) {
          targetSquad.expanded = squad.expanded = !squad.expanded;

          if (targetSquad.expanded) {
            this.expandedSquadsIds[targetSquad.temp_id] = true;
          } else {
            delete this.expandedSquadsIds[targetSquad.temp_id];
          }
        }

        return squad;
      });

      if (!targetSquad.expanded) {
        this.collapseChildren(targetSquad);
      }
    },
    collapseChildren(parent) {
      this.squads.forEach((squad) => {
        if (squad.parent_id !== parent.temp_id) return;

        if (squad.is_parent) this.collapseChildren(squad);

        squad.expanded = false;
        squad.parentExpanded = false;
        delete this.expandedSquadsIds[squad.temp_id];
      });
    },
    recalculateTree() {
      console.log("Config settings:", this.customSettings);
      this.preview();
    },
    submit() {
      const url = "/admin/org_csvs";

      postData(url, {
        squads: this.squads,
        associations_only: this.customSettings.associationsOnly,
        tree_type: this.treeType,
      }).then((res) => {
        console.log(`res:`, res);
        if (res.import_id) {
          this.activeImportId = res.import_id;
          this.poll();
        }
      });
    },
    poll() {
      if (!this.activeImportId) return;
      const fullUrl = `/admin/org_csvs/${this.activeImportId}/progress`;
      this.intervalId = setInterval(async () => {
        try {
          const response = await fetch(fullUrl);
          const data = await response.json();
          console.log(`data:`, data);

          this.progress = (data.items_processed / data.total_items) * 100 || 0;
          console.log(`progress:`, this.progress);
          this.progressPayload = data;

          // Stop polling if job is complete
          if (this.progress >= 100 || this.progressPayload.failed_at) {
            console.log("Clearing interval...");
            clearInterval(this.intervalId);
          }
        } catch (error) {
          console.error("Error fetching progress:", error);
        }
      }, 1000); // NOTE: POLLING INTERVAL
    },
    downloadJSON() {
      const json = JSON.stringify(this.squads, null, 2); // Convert squads to JSON
      const blob = new Blob([json], { type: "application/json" }); // Create a Blob
      const url = URL.createObjectURL(blob); // Create a URL for the Blob

      const a = document.createElement("a"); // Create an anchor element
      a.href = url; // Set the href to the Blob URL
      a.download = "squads.json"; // Set the download attribute
      document.body.appendChild(a); // Append the anchor to the body
      a.click(); // Programmatically click the anchor to trigger the download
      document.body.removeChild(a); // Remove the anchor from the document
      URL.revokeObjectURL(url); // Clean up the URL object
    },
    uniqueMembers(data) {
      const uniqueMembers = data.reduce((accumulator, squad) => {
        // Check if members exist and is an array
        if (Array.isArray(squad.members)) {
          // Add each member to the accumulator
          squad.members.forEach((member) => {
            accumulator.add(member); // Use a Set to store unique values
          });
        }
        return accumulator;
      }, new Set());

      return uniqueMembers;
    },
  };
}
