import { eqSets } from "../../shared/utils";

export function squadMultiselectV2(args) {
  const { onChange, userSquadIds, treatAllAsNone, singleSelect } = args;

  const selectedIds = new Set(args.selectedIds);

  let squads, rootIds;

  const showYourTeams =
    !!userSquadIds?.length &&
    !!args.selectedIds.every((id) => userSquadIds.includes(id));

  return {
    selectedIds,
    squads,
    childOfSelectedIds: new Set(),
    userSquadIds,
    singleSelect,
    defaultTab: window.currentUser.defaultTeamSelectTab,
    tab:
      showYourTeams && window.currentUser.defaultTeamSelectTab !== "all_teams"
        ? "your_teams"
        : "all_teams",
    open: false,
    ready: false,
    init() {
      this.$root.addEventListener("dx:squadsTree:init", (e) => {
        squads = e.detail;
        this.childOfSelectedIds = initializeChildOfSelectedIds(
          selectedIds,
          squads,
        );
        rootIds = new Set(squads.filter((s) => s.depth == 0).map((s) => s.id));
        this.ready = true;
      });
    },
    get selectedIdsArr() {
      return Array.from(this.selectedIds);
    },
    get selectedTeamsCount() {
      return this.selectedIds.size + this.childOfSelectedIds.size;
    },
    get selectedSquadNames() {
      if (!this.squads) return [];

      return this.squads
        .filter((s) => this.selectedIdsArr.includes(s.id))
        .map((s) => s.name);
    },
    toggleDropdown() {
      if (this.open) {
        return this.unfocus();
      }

      this.open = true;
      this.initTab();
    },
    changeTab(tab) {
      if (this.tab === tab) return;

      this.tab = tab;
      this.initTab();
    },
    initTab() {
      if (this.tab === "all_teams") {
        this.$nextTick(() => {
          // focus() isn't working w/o waiting a little longer after nextTick.
          // This is likely due to the fact that we're using multiple components in
          // squad_select_v2.
          setTimeout(() => {
            this.$refs.searchInput.focus();
          }, 50);
        });
      }
    },
    unfocus() {
      if (!this.open) return;
      this.open = false;
    },
    isSelected(id) {
      return this.selectedIds.has(id);
    },
    isChildOfSelectedId(id) {
      return this.childOfSelectedIds.has(id);
    },
    isDisabled(id) {
      if (singleSelect) {
        if (this.selectedIds.size != 1) {
          return this.isChildOfSelectedId(id);
        } else {
          // If single select, disable all non-selected
          return !this.selectedIds.has(id);
        }
      } else {
        return this.childOfSelectedIds.has(id);
      }
    },
    select(id) {
      if (this.childOfSelectedIds.has(id)) return;

      const selected = this.isSelected(id);
      const childIds = getChildIds(id, squads);

      if (selected) {
        // De-select team
        this.selectedIds.delete(id);
        childIds.forEach((cid) => this.childOfSelectedIds.delete(cid));
      }

      if (!selected) {
        // Select team
        this.selectedIds.add(id);
        childIds.forEach((cid) => {
          this.childOfSelectedIds.add(cid);
          this.selectedIds.delete(cid);
        });
      }

      this.$nextTick(() => {
        this.emitChange();
      });

      if (this.selectedIdsArr?.length && this.singleSelect) {
        this.unfocus();
      }
    },
    deselectAll() {
      this.selectedIds.clear();
      this.childOfSelectedIds.clear();
      this.unfocus();
      this.emitChange();
    },
    emitChange() {
      const allSelected = eqSets(this.selectedIds, rootIds);

      this.$nextTick(() => {
        if (allSelected && treatAllAsNone) {
          onChange(this.$root, []);
        } else {
          onChange(this.$root, Array.from(this.selectedIdsArr));
        }
      });
    },
  };
}

function getChildIds(parentId, squads) {
  const childIds = [];

  let parent = null;

  squads.forEach((squad) => {
    if (parentId == squad.id) {
      parent = squad;
      return;
    }

    if (parent && parent.depth < squad.depth) {
      childIds.push(squad.id);
    }

    if (parent && parent.depth >= squad.depth) {
      parent = null;
    }
  });

  return childIds;
}

function initializeChildOfSelectedIds(selectedIds, squads) {
  const childOfSelectedIds = new Set();

  let parent = null;

  squads.forEach((squad) => {
    if (selectedIds.has(squad.id)) {
      parent = squad;
      return;
    }

    if (parent && parent.depth < squad.depth) {
      childOfSelectedIds.add(squad.id);
    }

    if (parent && parent.depth >= squad.depth) {
      parent = null;
    }
  });

  return childOfSelectedIds;
}
