import { postData, sortFunc } from "../../shared/utils";

// NOTE: This logic should mostly stay in sync with app/javascript/application/components/triageResponsesModal.js.
// The other file is used for the read-only version of the Triage modal shown to contributors.

export function triageFormModal({
  triageSelectionsUrl,
  triageSaveUrl,
  snapshotBaseUrl,
  minResponseThreshold,
  snapshotSquadId,
  openAutomatically,
}) {
  return {
    open: false,
    loaded: false,
    snapshotItems: [],
    responseCount: 0,
    minResponseThreshold,
    comments: [],
    commentsOpen: false,
    commentsDriver: null,
    commentsLoaded: false,
    selections: {},
    submitting: false,
    showIncompleteSaveConfirmation: false,
    reusingPreviousResponses: false,
    compareTo: "vs_prev",
    showOrgComparision: true,
    init() {
      if (openAutomatically) this.openModal();
    },
    openModal() {
      this.open = true;
      this.fetchData();
    },
    fetchData() {
      fetch(`${triageSelectionsUrl}?squad_id=${snapshotSquadId}`)
        .then((response) => response.json())
        .then((resp) => {
          resp.forEach((triageResult) => {
            this.selections[triageResult.factor_id] = triageResult.selection;
          });
          this.fetchSnapshotItems();
        });
    },
    fetchSnapshotItems() {
      fetch(`${snapshotBaseUrl}/items?squad_ids=${snapshotSquadId}&branch=`)
        .then((response) => response.json())
        .then((resp) => {
          const sortedItems = resp.result_items
            .filter((i) => i.current_snapshot)
            .sort(sortFunc("priorityScoreAndCount"));

          const seenNames = [];
          const uniqueItems = [];
          sortedItems.forEach((item) => {
            if (!seenNames.includes(item.name.toLowerCase())) {
              uniqueItems.push(item);
              seenNames.push(item.name.toLowerCase());
            }
          });

          if (typeof resp.benchmark_factors == "object") {
            sortedItems.forEach((row) => {
              const benchmarkFactor = resp.benchmark_factors[row["id"]];

              if (
                benchmarkFactor &&
                row["score"] !== null &&
                row["score"] !== undefined
              ) {
                row["benchmark_50"] = benchmarkFactor["p_50"];
                row["benchmark_75"] = benchmarkFactor["p_75"];
                row["benchmark_90"] = benchmarkFactor["p_90"];

                row["vs_50th"] = row["score"] - benchmarkFactor["p_50"];
                row["vs_75th"] = row["score"] - benchmarkFactor["p_75"];
                row["vs_90th"] = row["score"] - benchmarkFactor["p_90"];
              }
            });
          }

          this.snapshotItems = uniqueItems.slice(0, 16);
          this.responseCount = resp.response_count;
          this.loaded = true;
        });
    },
    makeSelection(itemId, value) {
      this.selections[itemId] = value;
    },
    get hasAllSelections() {
      let valid = true;
      Object.values(this.snapshotItems).forEach((item) => {
        if (!this.selections[item.id]) valid = false;
      });
      return valid;
    },
    get isIncomplete() {
      return this.$store.triageSquadManager.incompleteSquads[snapshotSquadId];
    },
    save() {
      this.submitting = true;
      postData(`${triageSaveUrl}?squad_id=${snapshotSquadId}`, {
        selections: this.selections,
      }).then(() => {
        window.pushNotification({
          title: "Success!",
          desc: "Your responses were saved.",
        });

        if (this.$store?.triageSquadManager) {
          delete this.$store.triageSquadManager.incompleteSquads[
            snapshotSquadId
          ];
        }

        this.open = false;
        this.showIncompleteSaveConfirmation = false;
        this.reusingPreviousResponses = false;

        this.$nextTick(() => {
          this.submitting = false;
        });
      });
    },
    openComments(factorId) {
      this.commentsLoaded = false;
      this.commentsOpen = true;
      this.commentsDriver = this.snapshotItems.find((i) => i.id == factorId);

      fetch(
        `${snapshotBaseUrl}/comments?squad_ids=${snapshotSquadId}&factor_id=${factorId}&branch=`,
      )
        .then((response) => response.json())
        .then((resp) => {
          this.comments = resp;
          this.commentsLoaded = true;
        });
    },
    get maxPriority() {
      const arr = this.snapshotItems.map((i) => i.priority);

      return Math.max(...arr);
    },
    get maxDriverScore() {
      const arr = this.snapshotItems.map((i) => i.score);

      return Math.max(...arr);
    },
    percentageOfResponses(item, positionCount) {
      if (item.count === 0) return "0%";

      return `${Math.round((100 * item[positionCount]) / item.count)}%`;
    },
    changeComparison(compareTo) {
      this.compareTo = compareTo;
    },
    calculateItemComparison(item) {
      if (this.compareTo == "vs_50th") {
        if (item.benchmark_50 == null) return null;
        return item.score - item.benchmark_50;
      } else if (this.compareTo == "vs_75th") {
        if (item.benchmark_75 == null) return null;
        return item.score - item.benchmark_75;
      } else if (this.compareTo == "vs_90th") {
        if (item.benchmark_90 == null) return null;
        return item.score - item.benchmark_90;
      } else if (this.compareTo == "vs_prev") {
        if (item.previous_score == null) return null;
        return item.score - item.previous_score;
      } else if (this.compareTo == "vs_org") {
        if (item.org_score == null) return null;
        return item.score - item.org_score;
      }
    },
  };
}
